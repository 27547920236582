<template>
  <el-dialog :visible.sync="dialog" title="订单详情" append-to-body width="1000px">
   <el-table :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="eventDate" label="事件时间" >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.eventDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="eventType" label="事件类型" />
      <el-table-column prop="eventContent" label="事件详情"/>
    </el-table>
  </el-dialog>
</template>

<script>

import { parseTime } from '@/utils/index'

export default {
  name: 'Form',
  props: {
    sup_this: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    parseTime
  }
}
</script>

<style scoped>

.my-blockquote{
  margin: 0px 0px 10px;
  padding: 15px;
  line-height: 22px;
  border-left: 5px solid #00437B;
  border-radius: 0 2px 2px 0;
  background-color: #f2f2f2;
}
.el-row {
    margin-bottom: 20px;
    padding: 10px 0;
  }
  .el-col {
    border-radius: 4px;
  }
  .label {
    font-size: 18px;
    padding: 10px 10px;
  }
</style>
