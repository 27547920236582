<template>
  <div>
    <el-button size="mini" type="success" @click="to">查看</el-button>
    <eForm ref="form" :sup_this="sup_this"/>
  </div>
</template>
<script>
import eForm from './detail'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.data =this.data, 
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{display: inline-block;margin-right: 3px;}
</style>
