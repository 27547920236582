<template>
  <el-dialog :visible.sync="dialog" title="订单详情" append-to-body width="1000px">
    <blockquote class="my-blockquote">订单信息</blockquote>
    <el-row>
      <el-col :span="6"><span span class="label">所属商户:</span> <span>{{ data.merCode }}</span></el-col>
      <el-col :span="6"><span span class="label">业务类型:</span> <span>{{ parseType(data.busType) }}</span></el-col>
      <el-col :span="6"><span class="label">产品类型:</span> <span>{{ data.busProductCode }}</span></el-col>
      <el-col :span="6"><span span class="label">支付金额: </span><span>{{ data.busAmount }}</span></el-col>
    </el-row>
    <el-row>
      <el-col :span="12"><span span class="label">商户订单号:</span> <span>{{ data.merOrderNo }}</span></el-col>
      <el-col :span="12"><span span class="label">商户流水号: </span><span>{{ data.merSerialNo }}</span></el-col>
    </el-row>
    <el-row>
      <el-col ><span span class="label">请求时间:</span> <span>{{ parseTime(data.createTime) }}</span></el-col>
    </el-row>
    <blockquote class="my-blockquote">支付信息</blockquote>
      <el-row>
        <el-col :span="6"><span span class="label">渠道名称: </span><span>{{ data.channelName }}</span></el-col>
        <el-col :span="6"><span span class="label">渠道编号: </span><span>{{ data.busProtocolNo }}</span></el-col>
        <el-col :span="6"><span span class="label">支付状态: </span><span>{{ parseStatus(data.payStatus) }}</span></el-col>
        <el-col :span="6"><span span class="label">状态说明: </span><span>{{ data.payStatusRemark }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><span span class="label">银行名称:</span> <span>{{ data.busBankName }}</span></el-col>
        <el-col :span="6"><span class="label">开户城市:</span> <span>{{ data.busBankCity }}</span></el-col>
        <el-col :span="12"><span span class="label">开户分行:</span> <span>{{ data.busBankBranchName }}</span></el-col>
      </el-row>
       <el-row>
        <el-col :span="6"><span span class="label">银行卡号:</span> <span>{{ data.busBankAccount }}</span></el-col>
        <el-col :span="6"><span class="label">开户名:</span> <span>{{ data.busBankAccName }}</span></el-col>
        <el-col :span="12"><span span class="label">开户分行:</span> <span>{{ data.busBankBranchName }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><span span class="label">提交渠道时间:</span> <span>{{ parseTime(data.payRequestChannelTime) }}</span></el-col>
        <el-col :span="8"><span span class="label">渠道返回时间:</span> <span>{{ parseTime(data.payChannelReturnTime) }}</span></el-col>
        <el-col :span="8"><span span class="label">实际支付时间:</span> <span>{{ parseTime(data.payDealTime) }}</span></el-col>
      </el-row>
    <blockquote class="my-blockquote">其他信息</blockquote>
     <el-row>
        <el-col :span="10"><span span class="label">渠道批次号:</span> <span>{{ data.channelBatchNo }}</span></el-col>
        <el-col :span="7"><span span class="label">请求IP:</span> <span>{{ data.remoteIp }}</span></el-col>
        <el-col :span="7"><span span class="label">处理IP:</span> <span>{{ data.hostIp }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="10"><span span class="label">最近更新时间:</span> <span>{{ parseTime(data.updateTime) }}</span></el-col>      
      </el-row>
  </el-dialog>
</template>

<script>
import { parseTime } from '@/utils/index'
import { parseStatus,parseType } from './utils/index'
export default {
  name: 'Form',
  props: {
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false, loading: false, data:null, 
    }
  },
  methods: {
    parseTime,
    parseStatus,
    parseType
  }
}
</script>

<style scoped>

.my-blockquote{
  margin: 0px 0px 10px;
  padding: 15px;
  line-height: 22px;
  border-left: 5px solid #00437B;
  border-radius: 0 2px 2px 0;
  background-color: #f2f2f2;
}
.el-row {
    margin-bottom: 20px;
    padding: 10px 0;
  }
  .el-col {
    border-radius: 4px;
  }
  .label {
    font-size: 18px;
    padding: 10px 10px;
  }
</style>
