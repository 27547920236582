<template>
  <div>
    <el-button size="mini" type="success" @click="to">操作记录</el-button>
    <eForm ref="form" :sup_this="sup_this"/>
  </div>
</template>
<script>
import eForm from './record'
import { paymentProcessBySerialNo } from '@/api/dataPms'
export default {
  components: { eForm },
  props: {
    data: {
      type: String,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      paymentProcessInfo: null
    }
  },
  methods: {
    to() {
      if(this.paymentProcessInfo == null){
        this.queryPaymentProcess()
      }
      const _this = this.$refs.form
      _this.sup_this=this.sup_this
      _this.data =this.paymentProcessInfo
      _this.dialog = true
      },
    queryPaymentProcess() {
      const formData = { serialNo: this.data}
      const params = {'content':JSON.stringify(formData)}
      paymentProcessBySerialNo(params).then(res => {
          this.paymentProcessInfo = res.value
        })
    }
  }  
}
</script>

<style scoped>
  div{display: inline-block;margin-right: 3px;}
</style>
