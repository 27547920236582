<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select v-model="query.merCode" placeholder="请选择商户">
        <el-option
          v-for="item in queryMerOptions"
          :key="item.merCode"
          :label="item.merName"
          :value="item.merCode">
        </el-option>
      </el-select>
    <el-input v-model="query.serialNo" clearable placeholder="输入支付流水号" style="width: 200px;"  class="filter-item" @keyup.enter.native="toQuery"/>
     <el-input v-model="query.merOrderNo" clearable placeholder="输入商户订单号" style="width: 200px;"  class="filter-item" @keyup.enter.native="toQuery"/>
      <el-input v-model="query.merSerialNo" clearable placeholder="输入商户流水号" style="width: 200px;"  class="filter-item" @keyup.enter.native="toQuery"/>
      <el-select v-model="query.payStatus" clearable placeholder="请选择支付状态">
        <el-option
          v-for="item in queryStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="query.busType" clearable placeholder="请选择业务类型">
        <el-option
          v-for="item in queryBusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="query.channelName" clearable placeholder="请选择支付渠道">
        <el-option
          v-for="item in queryChannelOptions"
          :key="item.channelName"
          :label="item.channelName"
          :value="item.channelName">
        </el-option>
      </el-select>
    <el-date-picker
      class="filter-item"
      v-model="query.startRequestDate"
      type="date"
      placeholder="选择开始日期">
    </el-date-picker>
    <el-date-picker
      class="filter-item"
      v-model="query.endRequestDate"
      type="date"
      placeholder="选择结束日期">
    </el-date-picker>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import { parseTime } from '@/utils/index'
import eForm from './detail'
// 查询条件
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    },
    queryMerOptions: {
      type: Array,
      required: true
    },
    queryChannelOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      downloadLoading: false,
      queryStatusOptions: [
        { value: '201', label: '未支付' },
        { value: '202', label: '支付成功'},
        { value: '203', label: '支付失败'},
        { value: '204', label: '支付中'},
        { value: '205', label: '已过期'}
      ],
      queryBusOptions: [
        { value: '1', label: '代扣' },
        { value: '2', label: '代付'},
        { value: '3', label: '认证支付'},
        { value: '4', label: '协议支付'},
        { value: '5', label: '批扣'},
        { value: '6', label: '出金'},
        { value: '7', label: '入金'}
      ]
    }
  },
  methods: {
    checkPermission,
    // 去查询
    toQuery() {
      this.$parent.page = 1
      this.$parent.init()
    }
  }
}
</script>
