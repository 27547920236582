/**
 * Created by fengshi on 16/11/18.
 */

export function parseStatus(payStatus){
      if(payStatus==201){
        return '未支付'
      }else if(payStatus==202){
        return '支付成功'
      }else if(payStatus==203){
        return '支付失败'
      }else if(payStatus==204){
        return '支付中'
      }else if(payStatus==205){
        return '已过期'
      }
    }
    
export function parseType(busType){
      if(busType==1){
        return '代扣'
      }else if(busType==2){
        return '代付'
      }else if(busType==3){
        return '认证支付'
      }else if(busType==4){
        return '协议支付'
      }else if(busType==5){
        return '批扣'
      }else if(busType==6){
        return '出金'
      }else if(busType==7){
        return '入金'
      }
    }       

